<template>
  <el-dialog
    title="銷售報表顯示設定"
    width="60%"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form label-position="top">
      <el-form-item required label="報表資料是否排除儲值金付款訂單">
        <el-radio-group v-model="formData.excludeWallet">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <div class="flex justify-end">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button type="primary" @click="onConfirm">
        確定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue'
import { useDisplaySettings } from '@/use/displaySettings'

export default defineComponent({
  name: 'SalesReportDisplaySettingsDialog',
  setup (props, { emit }) {
    const { displaySettings, getDisplaySettings, updateDisplaySettings } = useDisplaySettings('salesReport', {
      excludeWallet: false,
    })
    const formData = reactive({
      excludeWallet: false,
    })
    const onConfirm = () => {
      updateDisplaySettings(formData)
      emit('confirm')
      emit('close')
    }

    const syncFormData = () => {
      for (const key in displaySettings.value) {
        formData[key] = displaySettings.value[key]
      }
    }

    onMounted(() => {
      getDisplaySettings()
      syncFormData()
    })

    return {
      formData,
      onConfirm,
      displaySettings,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
