<template>
  <el-dialog
    class="dialog"
    title="銷售報表 Google Sheet"
    :visible="true"
    width="60%"
    :close-on-click-modal="false"
    @close="run=false;$emit('close')"
  >
    <el-progress type="circle" color="#2BB8C1" :percentage="sheet.progress" :status="progressStatus" />
    <div>
      <div v-if="sheet.isProgress">連結產生中</div>
      <div v-if="!sheet.isProgress && !sheet.isError">Google Sheet 連結將在 7 天後自動刪除</div>
      <div v-if="!sheet.isProgress && sheet.isError">產生報表出現狀況！請回報：{{ sheet.errorMessage }}</div>
      <a v-if="!sheet.isProgress && !sheet.isError" :href="sheet.url" target="_blank">{{ sheet.url }}</a>
    </div>
  </el-dialog>
</template>

<script>
import {
  CreateSalesReportSheet,
  GetSalesReportSheet,
} from '@/api/sales'
// TODO 密碼輸入
export default {
  name: 'GoogleSheetDialog',

  props: {
    shopId: String,
    start: Date,
    end: Date,
  },
  data () {
    return {
      run: false,
      sheetId: null,
      sheet: {
        progress: 0,
        isProgress: true,
        isError: false,
      },
    }
  },
  computed: {
    progressStatus () {
      if (this.sheet.isProgress) return ''
      if (this.sheet.isError) return 'warning'
      return 'success'
    },
  },
  async created () {
    try {
      const res = await CreateSalesReportSheet({
        shopId: this.shopId,
        start: this.start,
        end: this.end,
      })
      this.sheetId = res.id
      this.run = true
      this.checkSheetProgress()
    } catch (error) {
      console.log(error)
      this.$message.error({
        message: error || error.message,
      })
      this.$emit('close')
    }
  },
  methods: {
    async checkSheetProgress () {
      if (!this.run) return
      const res = await GetSalesReportSheet({
        id: this.sheetId,
        shopId: this.shopId,
      })
      this.sheet = res
      if (res.isProgress) {
        setTimeout(this.checkSheetProgress, 1000)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.dialog {
  text-align: center;
}
</style>
