<template>
  <div ref="targetDom" class="export-container">
    <div
      v-for="dataObject in exportDataList"
      :key="dataObject.category"
      class="pdf-item"
    >
      <table>
        <caption>{{ convertCategoryName(dataObject.category) }}報表</caption>
        <tr>
          <td colspan="2" class="text-left bg-gray">匯出人員</td>
          <td colspan="4" class="text-left">{{ userName || '-' }}</td>
          <td colspan="2" class="text-left bg-gray">匯出時間</td>
          <td colspan="4" class="text-left">{{ new Date().toLocaleString() }}</td>
        </tr>
        <tr>
          <td colspan="2" class="text-left bg-gray">計算期間</td>
          <td colspan="4" class="text-left">
            {{ dateTime(timeRange[0]) }}~{{ dateTime(timeRange[1]) }}
          </td>
          <td colspan="2" class="text-left bg-gray">類別實收總額</td>
          <td colspan="4" class="text-left">{{ `NT$${getSummary(dataObject.data, 'totalPaymentPrice')}` }}</td>
        </tr>
        <tr>
          <th
            v-for="label in headerOptions"
            :key="label"
            class="bg-gray"
          >
            {{ label }}
          </th>
        </tr>
        <tr
          v-for="item in dataObject.data"
          :key="item.id"
          class="table-item"
        >
          <td>{{ getProducts(item.SalesRecordItems) }}</td>
          <td>{{ dateTime(item.sellAt) }}</td>
          <td>{{ item.Member ? item.Member && item.Member.UserInfo && item.Member.UserInfo.name : '非會員' }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ `NT$${item.totalItemsPrice}` }}</td>
          <td>{{ `NT$${item.totalPaymentPrice}` }}</td>
          <td>{{ `NT$${item.totalDiscountPrice}` }}</td>
          <td>{{ getDiscounts(item.SalesRecordDiscounts) }}</td>
          <td>{{ `NT$${getCash(item.SalesRecordPayments)}` }}</td>
          <td>{{ `NT$${getCredit(item.SalesRecordPayments)}` }}</td>
          <td>{{ `NT$${getOther(item.SalesRecordPayments)}` }}</td>
          <td>{{ convertStatus(item.status) }}</td>
        </tr>
        <tfoot>
          <tr>
            <td colspan="4">總計</td>
            <td class="bg-gray">{{ `NT$${getSummary(dataObject.data, 'totalItemsPrice')}` }}</td>
            <td class="bg-gray">{{ `NT$${getSummary(dataObject.data, 'totalPaymentPrice')}` }}</td>
            <td class="bg-gray">{{ `NT$${getSummary(dataObject.data, 'totalDiscountPrice')}` }}</td>
            <td>-</td>
            <td class="bg-gray">{{ `NT$${getUseSummary(dataObject.data, 'cash')}` }}</td>
            <td class="bg-gray">{{ `NT$${getUseSummary(dataObject.data, 'credit')}` }}</td>
            <td class="bg-gray">{{ `NT$${getUseSummary(dataObject.data, 'other')}` }}</td>
            <td>-</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { dateTimeFormat } from '@/utils/helper'
import { salesRecordStatusOptions } from '@/config/sales'
import JsPDF from 'jspdf'
import html2canvas from 'html2canvas'

export default {
  name: 'ExportHtmlToPdf',

  props: {
    timeRange: {
      type: Array,
      required: true,
      default: () => [],
    },
    categoryOptions: {
      type: Array,
      default: () => [],
    },
    exportDataList: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    headerOptions: [
      '產品',
      '建立時間',
      '姓名',
      '電話號碼',
      '訂單總額',
      '付款金額',
      '折扣金額',
      '使用優惠',
      '使用現金',
      '使用信用卡',
      '使用其他',
      '狀態',
    ],
    summary: {
      itemsPrice: 0,
      discountsPrice: 0,
      paymentPrice: 0,
      cash: 0,
      credit: 0,
      other: 0,
    },
  }),

  computed: {
    ...mapGetters([
      'userName',
    ]),

    exportFileName () {
      const startTime = this.fileNameDateTime(this.timeRange[0])
      const endTime = this.fileNameDateTime(this.timeRange[1])

      return `銷售報表 ${startTime} 至 ${endTime}`
    },
  },

  watch: {
    'dataObject.data' () {
      this.getSummary()
    },
  },

  methods: {
    async generateReport () {
      const targetDom = document.querySelector('.export-container')

      const A4_WIDTH = 830
      const A4_HEIGHT = 592.28
      const target = this.$refs.targetDom

      const pageHeight = target.scrollWidth / A4_WIDTH * A4_HEIGHT
      const labelListID = document.getElementsByClassName('pdf-item')
      for (let i = 0; i < labelListID.length; i++) {
        const itemHeight = i + 1 === labelListID.length ? labelListID[i].offsetHeight + 50 : (labelListID[i + 1].offsetTop - labelListID[i].offsetTop) + 32
        const divParent = labelListID[i].parentNode // 获取该div的父节点
        if (pageHeight - Math.ceil(itemHeight) <= 0) {
          const itemListId = labelListID[i].getElementsByClassName('table-item')
          let totalHeight = 0
          for (const tableItem of itemListId) {
            totalHeight += tableItem.clientHeight
          }
          const foot = labelListID[i].getElementsByTagName('tfoot')[0]
          const footHeight = foot.clientHeight
          let height = itemHeight - totalHeight - footHeight - 32
          let j = 0
          const shift = 5
          for (const tableItem of itemListId) {
            height += tableItem.clientHeight
            if (pageHeight - height <= 0) {
              const newNode = document.createElement('div')
              newNode.className = 'pagination-block'
              newNode.style.height = `${pageHeight - (height - tableItem.clientHeight) + 32 + shift}px`
              const divParent = itemListId[j - 1].parentNode
              const next = itemListId[j - 1].nextSibling
              divParent.insertBefore(newNode, next)
              height = tableItem.clientHeight
            }
            j++
          }
          const newNode = document.createElement('div')
          newNode.className = 'pagination-block'
          newNode.style.height = `${pageHeight - (height + footHeight + 64 + shift)}px`
          const next = labelListID[i].nextSibling
          // 判断兄弟节点是否存在
          if (next) {
            if (next.className !== 'pagination-block') {
              divParent.insertBefore(newNode, next)
            }
          } else {
            divParent.appendChild(newNode)
          }
        } else {
          const newNode = document.createElement('div')
          newNode.className = 'pagination-block'
          newNode.style.height = `${pageHeight - Math.ceil(itemHeight)}px`
          const next = labelListID[i].nextSibling
          // 判断兄弟节点是否存在
          if (next) {
            if (next.className !== 'pagination-block') {
              divParent.insertBefore(newNode, next)
            }
          } else {
            divParent.appendChild(newNode)
          }
        }
      }

      await html2canvas(targetDom, {
        scale: 1,
        useCORS: true,
        imageTimeout: 1000,
        height: targetDom.scrollHeight,
        width: targetDom.scrollWidth,
        dpi: window.devicePixelRatio,
      }).then(canvas => {
        const A4_WIDTH = 830
        const A4_HEIGHT = 592.28
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        const pageData = canvas.toDataURL()
        // 一页pdf html页面生成的canvas高度;
        const pageHeight = contentWidth / A4_WIDTH * A4_HEIGHT
        // 未生成pdf的html页面高度
        let leftHeight = contentHeight
        // 页面偏移
        let position = 0
        // a4纸的尺寸[592.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        const imgWidth = A4_WIDTH
        const imgHeight = A4_WIDTH / contentWidth * contentHeight
        // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
        const pdf = new JsPDF('l', 'pt', 'a4')
        // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度
        // 当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
          pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight, '', 'FAST')
        } else {
          while (leftHeight > 0) {
            pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight, '', 'FAST')
            leftHeight -= pageHeight
            position -= A4_HEIGHT
            // 避免添加空白页
            if (leftHeight > 0) {
              pdf.addPage()
            }
          }
        }
        // ---- 建立分頁數字
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text(i + ' / ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.94), (pdf.internal.pageSize.getHeight() - 10))
        }
        // 直接下载
        pdf.save(this.exportFileName + '.pdf')
      }).then(() => {
        this.removePaginationBlock()
      })
    },
    removePaginationBlock () {
      const labelId = document.getElementsByClassName('pagination-block')
      while (labelId[0]) {
        labelId[0].parentNode.removeChild(labelId[0])
      }
    },
    onProgress (event) {
      console.log('onProgress', event)
    },

    hasStartedGeneration () {
      console.log('hasStartedGeneration')
    },

    hasGenerated (event) {
      console.log('hasGenerated', event)
    },

    dateTime (dateTime) {
      return dateTimeFormat(dateTime).dateTime.replaceAll('-', '/')
    },

    fileNameDateTime (dateTime) {
      return dateTimeFormat(dateTime).dateTime.replaceAll(':', '')
    },

    getProducts (products) {
      if (!products.length) return '-'

      let productsList = ''
      for (let i = 0; i < products.length; i += 1) {
        if (i === 0) productsList += `${products[i].name}`
        else productsList += `、${products[i].name}`
      }

      return productsList
    },

    getDiscounts (discounts) {
      if (!discounts.length) return '-'

      let discountsList = ''
      for (let i = 0; i < discounts.length; i += 1) {
        if (i === 0) discountsList += `${discounts[i].name}`
        else discountsList += `、${discounts[i].name}`
      }

      return discountsList
    },

    getCash (payment) {
      const cash = payment.find(item => item.type === 'cash')

      return cash ? cash.amount : 0
    },

    getCredit (payment) {
      const credit = payment.find(item => item.type === 'credit')

      return credit ? credit.amount : 0
    },

    getOther (payment) {
      const other = payment.filter(item => item.type !== 'cash' && item.type !== 'credit')
      const otherPayment = other.reduce(
        (previous, current) => previous + current.amount,
        0,
      )

      return otherPayment
    },

    getSummary (data, type) {
      return data
        ? data.reduce((previous, current) => previous + current[type], 0)
        : 0
    },

    getUseSummary (data, type) {
      const cash = data.reduce((previous, current) => previous + this.getCash(current.SalesRecordPayments), 0)
      const credit = data.reduce((previous, current) => previous + this.getCredit(current.SalesRecordPayments), 0)
      const other = data.reduce((previous, current) => previous + this.getOther(current.SalesRecordPayments), 0)

      return data && type === 'cash'
        ? cash
        : data && type === 'credit'
          ? credit
          : data && type === 'other'
            ? other
            : 0
    },

    convertStatus (status) {
      return salesRecordStatusOptions[status]
    },

    convertCategoryName (categoryId) {
      const categoryOption = this.categoryOptions.find(item => item.id === categoryId)
      return categoryOption ? categoryOption.name : ''
    },
  },
}
</script>

<style scoped>
table {
  margin: 32px 12px;
}

caption {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

th,
td {
  min-width: 90px;
  margin: 0;
  padding: 0 0 12px;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  vertical-align: middle;
  border: 1px solid black;
}

td {
  text-align: center;
}

.text-left {
  padding-left: 8px;
  text-align: left;
}

.bg-gray {
  background-color: #f1f1f1;
}
</style>
