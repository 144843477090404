import ls from 'local-storage'
import { get, isEmpty } from 'lodash'
import { ref } from 'vue'

export const useDisplaySettings = (settingsKey, settingsDefault) => {
  const displaySettings = ref(null)

  const initDisplaySettingsStore = () => {
    let displaySettingsStore = ls.get('displaySettings')
    if (!displaySettingsStore) ls.set('displaySettings', {})
    if (!settingsKey) return
    const targetDisplaySettings = get(displaySettingsStore, settingsKey, null)
    if (!targetDisplaySettings || isEmpty(targetDisplaySettings)) {
      displaySettingsStore = ls.get('displaySettings')
      if (settingsDefault) {
        displaySettingsStore[settingsKey] = settingsDefault
      } else {
        displaySettingsStore[settingsKey] = {}
      }
      ls.set('displaySettings', displaySettingsStore)
    }
  }

  const getDisplaySettings = () => {
    if (!settingsKey) return
    initDisplaySettingsStore()
    const displaySettingsStore = ls.get('displaySettings')
    displaySettings.value = get(displaySettingsStore, settingsKey, null)
  }

  const updateDisplaySettings = (newData) => {
    if (!settingsKey) return
    const displaySettingsStore = ls.get('displaySettings')
    displaySettingsStore[settingsKey] = newData
    ls.set('displaySettings', displaySettingsStore)
    getDisplaySettings()
  }

  return {
    initDisplaySettingsStore,
    displaySettings,
    getDisplaySettings,
    updateDisplaySettings,
  }
}
